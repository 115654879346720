import React from 'react'

import { BrowserRouter as Router, Route } from 'react-router-dom';

import Join from '../src/components/Join/Join'
import Chat from '../src/components/Chat/Chat'

const App = () => (
    <Router>
        <Route path="/" exact component={Join} />
        <Route path="/chat" exact component={Chat} />
    </Router>
);

export default App;
